import React from 'react'
import GatsbyImage from 'gatsby-image'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import ArrowButton from '../../ui/arrow-button'
import useTestimonials from '../../use-testimonials'

const TestimonialsContainer = styled.div`
  border: 1px solid ${p => p.theme.border.line};
  position: relative;
`

const InnerContainer = styled.div`
  padding: 5% 15% 5% 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: white;
`

const AuthorContainer = styled.h1`
  font-size: 1.6rem;
  margin-bottom: 8px;
  text-transform: uppercase;
`

const RoleContainer = styled.p`
  font-size: 0.9rem;
  color: ${p => p.theme.text.black[200]};
`

const TextContainer = styled.p`
  margin-bottom: 0;
  margin-top: 1.5rem;
  white-space: pre-line;
  line-height: 2.2rem;
`

const ArrowContainer = styled.div`
  font-size: 1.6rem;
  position: absolute;
  cursor: pointer;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%)
    translateX(${p => (p.side === 'left' ? '-' : '+')}50%);
  ${p => `${p.side}: 0;`};
`

const SGatsbyImage = styled(GatsbyImage)`
  margin-left: auto;
  margin-right: auto;
  img {
    height: 50% !important;
    top: auto !important;
    bottom: 0px !important;
    object-fit: contain !important;
  }
`

const ArrowRight = styled(ArrowButton)`
  ${p => p.css};
  transform: rotate(360deg);
  :hover,
  :focus {
    transform: translateX(0.2vw);
  }
`
const ArrowLeft = styled(ArrowRight)`
  ${p => p.css};
  transform: rotate(180deg);
  :hover,
  :focus {
    transform: rotate(180deg) translateX(0.2vw);
  }
`

const Title = styled.div`
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3vw;
  font-weight: bold;
  ${props => props.theme.media.xs`
      font-size: 1.2rem;
  `};
`

const Testimonials = () => {
  const testimonials = useTestimonials()
  const carouselRef = React.useRef(null)

  return (
    <>
      <Title>Testimonials</Title>
      <TestimonialsContainer>
        <ArrowContainer side="left">
          <ArrowLeft
            gradient
            onClick={() =>
              carouselRef.current && carouselRef.current.onClickPrev()
            }
          />
        </ArrowContainer>
        <Carousel
          className="testimonial-carousel"
          ref={carouselRef}
          centerMode
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          swipeable={false}
          interval={6000}
          centerSlidePercentage={100}
          transitionTime={400}
          infiniteLoop
          autoPlay
          stopOnHover
        >
          {testimonials.map(testimonial => (
            <InnerContainer key={testimonial.author}>
              <div>
                <AuthorContainer>{testimonial.author}</AuthorContainer>
                <RoleContainer>{testimonial.role}</RoleContainer>
              </div>
              <TextContainer>{testimonial.testimonial}</TextContainer>
              <SGatsbyImage critical loading={"eager"} fixed={testimonial.logo.node.fixed} />
            </InnerContainer>
          ))}
        </Carousel>
        <ArrowContainer side="right">
          <ArrowRight
            gradient
            onClick={() =>
              carouselRef.current && carouselRef.current.onClickNext()
            }
          />
        </ArrowContainer>
      </TestimonialsContainer>
    </>
  )
}

export default Testimonials

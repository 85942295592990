import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import useTeam from '../../hooks/use-team'
import GithubIcon from '../../images/github'
import LinkedInIcon from '../../images/linked-in'
import TwitterIcon from '../../images/twitter'
import CircleButton from '../../ui/circle-button'
import AboutUsItemLayout from './about-us-item-layout'
import GatsbyImage from 'gatsby-image'

const joinStyles = {
  container: css`
    text-decoration: none;
    color: inherit;
  `,
}

const MembersContainer = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  ${props => props.theme.media.md`
    grid-template-columns: repeat(2, minmax(140px, 250px));
    grid-template-rows: repeat(2, 1fr);
     row-gap: 2rem;
    justify-content: center;
  `};
`

const TeamImageContainer = styled.div`
  ${props => props.join && joinStyles.container};
`

joinStyles.item = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.secondary};
  ${TeamImageContainer}:hover & {
    background-color: ${props => props.theme.gradients.secondary.hovered};
  }
  :after {
    content: '';
    float: left;
    padding-top: ${p => (p.odd ? 120 : 130)}%;
  }
`

const ImageContainer = styled.div`
  position: relative;
  ${props => props.join && joinStyles.item};
`

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  > div {
    padding-bottom: ${p => (p.odd ? 120 : 130)}% !important;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: ${p => p.theme.palette.secondary}00;
  transition: background 200ms ease-in-out;
  display: flex;
  ${ImageContainer}:hover & {
    background: ${p => p.theme.palette.secondary}; // edge
    background: ${p => p.theme.palette.secondary}e6;
  }
`

const TeamMemberSocials = styled.div`
  margin: auto;
  opacity: 0;
  ${ImageOverlay}:hover & {
    opacity: 1;
  }
  transition: opacity 200ms ease-in-out;
  a {
    color: white;
    :not(:last-child) {
      margin-right: 24px;
    }
  }
`

const Name = styled.h1`
  margin: 1rem 0 0.5rem;
  white-space: pre-line;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem;
`

const Role = styled.div`
  font-size: 0.8rem;
  color: ${p => p.theme.text.black[200]};
`

const Team = () => {
  const { team, getImage } = useTeam()

  return (
    <AboutUsItemLayout title="Leadership Team">
      <MembersContainer>
        {team.map(({ name, role, linkedIn, github, twitter }, i) => {
          const isLast = team.length === i + 1
          return (
            <TeamImageContainer
              key={i}
              join={isLast}
              {...(isLast && { as: Link, to: '/careers/', join: '' })}
            >
              <ImageContainer join={isLast} odd={i % 2 === 0}>
                {isLast ? (
                  <CircleButton color="secondary">+</CircleButton>
                ) : (
                  <>
                    <StyledImage
                      alt={name}
                      title={name}
                      fluid={getImage(i)}
                      odd={i % 2 === 0}
                    />
                    <ImageOverlay>
                      <TeamMemberSocials>
                        <a
                          href={linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon width={22} height={22} />
                        </a>
                        <a
                          href={github}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <GithubIcon width={22} height={22} />
                        </a>
                        <a
                          href={twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterIcon width={22} height={22} />
                        </a>
                      </TeamMemberSocials>
                    </ImageOverlay>
                  </>
                )}
              </ImageContainer>
              <Name>{name}</Name>
              <Role>{role}</Role>
            </TeamImageContainer>
          )
        })}
      </MembersContainer>
    </AboutUsItemLayout>
  )
}

export default Team

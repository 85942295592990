import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import useClients from '../../hooks/use-clients'

const RootWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 2vw 0;
  ${props => props.theme.media.sm`
    :not(:first-child) {
      border-left: none;
    }
  `}
`

const TitleWrapper = styled.div`
  text-align: ${props => props.right && 'right'};
  ${props => props.theme.media.sm`
    text-align: left;
  `}
`

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  ${props => props.theme.media.xs`
    font-size: 1.2rem;
  `}
`

const SubTitle = styled.div`
  font-size: 1.05rem;
  color: ${p => p.theme.text.black[200]};
  position: absolute;
  width: 100%;
  ${props => props.theme.media.sm`
    font-size: 0.9rem;
  `}
`

const LogosWrapper = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(130px, 200px));
  ${props => props.theme.media.md`
    grid-template-columns: repeat(4, minmax(100px, 33%));
  `};
  ${props => props.theme.media.sm`
    grid-template-columns: repeat(3, minmax(100px, 33%));
  `};
  row-gap: 1rem;
  justify-content: space-between;
`

const ClientsLogosWrapper = styled(LogosWrapper)`
  margin-left: -3vw;
  ${props => props.theme.media.sm`
    margin-right: -7vw;
    margin-left: -7vw;
  `};
`

const LogoItem = styled.a`
  display: table-cell;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(225, 225, 229);
  &:hover {
    background: ${props => props.theme.palette.secondary};
    color: ${p => p.theme.text.white.main};
    img {
      filter: brightness(1.1);
    }
  }
  position: relative;
  transition: background 300ms ease-in-out;
  :after {
    content: '';
    float: left;
    padding-top: 100%;
  }
`

const LogoImage = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  padding: 15% 15%;
  display: flex;
  align-items: center;
`

const SGatsbyImage = styled(GatsbyImage)`
  width: 100% !important;
  height: 100% !important;
  img {
    object-fit: contain !important;
    max-height: 80% !important;
  }
`

const LogoName = styled.div`
  text-align: center;
  color: white;
  font-weight: bold;
  position: relative;
  top: 80%;
  font-size: 1rem;
`

const LandingClientsAndPartners = () => {
  const clients = useClients()
  return (
    <RootWrapper>
      <SectionWrapper>
        <TitleWrapper>
          <Title>CLIENTS AND PARTNERS</Title>
          <SubTitle>
            Some of the companies and projects we are collaborating closely with
          </SubTitle>
        </TitleWrapper>
        <ClientsLogosWrapper>
          {clients.map(client => (
            <LogoItem
              key={client.company}
              href={client.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoImage>
                <SGatsbyImage fluid={client.logo} />
              </LogoImage>
              <LogoName>{client.company}</LogoName>
            </LogoItem>
          ))}
        </ClientsLogosWrapper>
      </SectionWrapper>
    </RootWrapper>
  )
}

export default LandingClientsAndPartners

import { graphql, useStaticQuery } from 'gatsby'

export default function useTestimonials() {
  const { site, img } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            testimonials {
              author
              role
              testimonial
              company
            }
          }
        },
        img: allImageSharp(
          filter: { fixed: { src: { regex: "/testimonial-logo/" } } }
          sort: { order: ASC, fields: sizes___originalName }
        ) {
          edges {
            node {
              fixed(height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `,
  )
  const images = img.edges
  const testimonials = site.siteMetadata.testimonials
  return testimonials.map(testimonial => ({
    ...testimonial,
    logo: images.find((img) => new RegExp(testimonial.company, 'i').test(img.node.fixed.src))
  }))
}

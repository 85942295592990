import React from 'react'
import About from '../components/about-us/about'
import History from '../components/about-us/history'
import Team from '../components/about-us/team'
import Container from '../components/container'
import LandingClientsAndPartners from '../components/landing/landing-clients-partners'
import LandingMapAndContact from '../components/landing/landing-map-contact'
import LandingQuestionsAndProjects from '../components/landing/landing-questions-projects'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'
import Testimonials from '../components/testimonials/testimonials'

const AboutUsPage = ({ animate }) => {
  return (
    <>
      <SEO
        title="About Us"
        description="We are team of tech enthusiasts who appreciate quality code, beautiful design and friendly work atmosphere. We share a special passion for Distributed Ledger Technology and enjoy exploring the disruptive potential of Blockchain. We are always looking for new ways to meet the challenges of tomorrow."
      />
      <Container mb="6vw">
        <PageTitle title="About us" animate={animate} />
      </Container>
      <Container width="88vw" mb="10vmax">
        <About />
      </Container>
      <Container width="88vw" mb="10vmax">
        <History />
      </Container>
      <Container width="88vw" mb="10vmax">
        <Team />
      </Container>
      <Container width="88vw" mb="5vmax">
        <LandingClientsAndPartners />
      </Container>
      <Container width="60vw" mb="10vmax">
        <Testimonials />
      </Container>
      <LandingQuestionsAndProjects project={false} />
      <LandingMapAndContact />
    </>
  )
}

export default AboutUsPage

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import AboutUsItemLayout from './about-us-item-layout'
import styled from 'styled-components'

const HistoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${props => props.theme.media.sm`
    grid-template-columns: repeat(1, minmax(200px, 300px));
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
  `};
  ${props => props.theme.media.xs`
    grid-template-columns: repeat(1, 1fr);
  `};
`

const Value = styled.div`
  position: absolute;
  font-size: 4.2rem;
  font-weight: bold;
  left: 10%;
  top: 10%;
  ${props => props.theme.media.xs`
    font-size: 5rem;
  `};
`

const Key = styled.div`
  position: absolute;
  width: 100%;
  top: 60%;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  ${props => props.theme.media.xs`
    font-size: 1.2rem;
  `};
  color: ${p => p.theme.text.black[200]};
`

const HistoryItemWrapper = styled.div`
  display: table-cell;
  border: 1px solid ${props => props.theme.border.main};
  position: relative;
  &:not(:last-child) {
    border-right: none;
    ${props => props.theme.media.sm`
      border-right: 1px solid ${props => props.theme.border.main};
      border-bottom: none;
    `};
  }
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.palette.secondary};
    &,
    ${Key} {
      color: white;
    }
  }
  transition: all 200ms ease-in-out;
  :after {
    content: '';
    float: left;
    padding-top: 100%;
  }
`

const HistoryItem = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  white-space: pre;
  text-align: center;
`

const History = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            history {
              key
              value
            }
          }
        }
      }
    `,
  )
  return (
    <AboutUsItemLayout title="Techpad this">
      <HistoryContainer>
        {site.siteMetadata.history.map(item => (
          <HistoryItemWrapper key={item.key}>
            <HistoryItem>
              <Value>{item.value}</Value>
              <Key>{item.key}</Key>
            </HistoryItem>
          </HistoryItemWrapper>
        ))}
      </HistoryContainer>
    </AboutUsItemLayout>
  )
}

export default History

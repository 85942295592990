import React from 'react'
import LandingAboutUs from '../landing/landing-about-us'
import AboutUsItemLayout from './about-us-item-layout'
import styled from 'styled-components'

const AboutUsWrapper = styled.div`
  font-size: 2.6rem;
  line-height: 3.6rem;
  ${props => props.theme.media.sm`
    font-size: 1.8rem;
    line-height: 2.8rem;
  `};
`

const About = () => (
  <AboutUsItemLayout title="Who we are">
    <AboutUsWrapper>
      <LandingAboutUs variant="long" withNote />
    </AboutUsWrapper>
  </AboutUsItemLayout>
)

export default About

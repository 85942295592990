import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowLink from '../../ui/arrow-link'
import Animate from '../animate'

const StyledArrowLink = styled(ArrowLink)`
  font-size: 1.1rem;
  margin: 4vw auto 0;
`

const Wrapper = styled.div`
  ${p => p.css};
  animation-name: ${p => p.theme.animations.landingPageText};
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  text-align: center;
  .lighter {
    color: ${p => p.theme.text.black[200]};
    display: inline;
  }
`

const Note = styled.div`
  font-size: 1rem;
  width: 55%;
  ${props => props.theme.media.sm`
    width: 100%;
  `};
  margin: 2rem auto 0;
  text-align: center;
  line-height: 1.8rem;
`

const LandingAboutUs = ({ animate, withLink, variant, withNote }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            about {
              long
              short
              note
            }
          }
        }
      }
    `,
  )
  return (
    <Animate duration="400ms" animate={animate}>
      {props => (
        <Wrapper {...props}>
          <Text
            dangerouslySetInnerHTML={{
              __html: site.siteMetadata.about[variant],
            }}
          />
          {withNote && <Note>{site.siteMetadata.about.note}</Note>}
          {withLink && (
            <StyledArrowLink label="about us" to="/about-us/" gradient />
          )}
        </Wrapper>
      )}
    </Animate>
  )
}

LandingAboutUs.propTypes = {
  withLink: PropTypes.bool,
  variant: PropTypes.oneOf(['short', 'long']),
  withNote: PropTypes.bool,
}

export default LandingAboutUs

import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const AboutUsItemWrapper = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `}
`

const Title = styled.div`
  text-transform: uppercase;
  flex: none;
  width: 300px;
  font-weight: bold;
  font-size: 1rem;
  ${props => props.theme.media.sm`
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.2rem;
  `};
`

const Body = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`

const BodyInner = styled.div`
  position: relative;
  left: -150px;
  width: 75%;
  margin: auto;
  ${props => props.theme.media.md`
    width: 90%;
  `};
  ${props => props.theme.media.sm`
    position: static;
    width: 100%;
  `};
`

const AboutUsItemLayout = ({ children, title }) => (
  <AboutUsItemWrapper>
    <Title>{title}</Title>
    <Body>
      <BodyInner>{children}</BodyInner>
    </Body>
  </AboutUsItemWrapper>
)

AboutUsItemLayout.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
}

export default AboutUsItemLayout

import { graphql, useStaticQuery } from 'gatsby'

export default function useClients() {
  const { site, img } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            clients {
              company
              url
            }
          }
        }
        img: allImageSharp(
          filter: { fixed: { src: { regex: "/client-logo/" } } }
          sort: { order: ASC, fields: sizes___originalName }
        ) {
          edges {
            node {
              fixed(height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `,
  )
  const clients = site.siteMetadata.clients
  const images = img.edges
  return clients.map((client, i) => ({
    ...client,
    logo: images[i].node.fixed,
  }))
}

import { graphql, useStaticQuery } from 'gatsby'

export default function useTeam() {
  const { site, img } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            team {
              name
              role
              linkedIn
              twitter
              github
            }
          }
        }
        img: allImageSharp(
          filter: { fluid: { src: { regex: "/member/" } } }
          sort: { order: ASC, fields: sizes___originalName }
        ) {
          edges {
            node {
              fluid(quality: 90, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )
  const team = site.siteMetadata.team
  const images = img.edges

  return {
    team,
    images,
    getImage: i => images[i].node.fluid,
  }
}
